import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import htmlClass from "./modules/htmlclass";
import config from "./modules/config";
import breadcrumbs from "./modules/breadcrumbs";
import profile from "./modules/profile";

import orders from "./modules/orders";
import wallets from "./modules/wallet";
import company from "./modules/company";
import user from "./modules/user";
import paymentunits from "./modules/paymentunits";
import dashboard from "./modules/dashboard";
import representative from "./modules/representative";
import pos from "./modules/pos";
import calculator from "./modules/calculator";
import mcmWallet from "./modules/mcmWallet";

// custom plugins
import statePersistorPlugin from "./plugins/state-persistor";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: auth,
    htmlClass: htmlClass,
    config: config,
    breadcrumbs: breadcrumbs,
    profile: profile,
    orders: orders,
    wallets: wallets,
    company: company,
    user: user,
    paymentunits,
    dashboard,
    representative,
    pos,
    calculator,
    mcmWallet
  },
  plugins: [statePersistorPlugin]
});
