/* eslint-disable no-extra-boolean-cast */
import Api from "../../services/api";
import { areAllValuesEqualTo } from "../../utils/arrays-includes";

const company = {
  state: () => ({
    MAIN_COMPANY: JSON.parse(sessionStorage.getItem("_mainCompany") || "{}"),
    SELECTED_COMPANY: null,
    kycKybDetailsLoading: false,
    kycKybDetails: null,
    kycFilesStatus: "rejected"
  }),
  mutations: {
    SET_MAIN_COMPANY(state, company) {
      state.MAIN_COMPANY = company;
    },
    SET_SELECTED_COMPANY(state, company) {
      state.SELECTED_COMPANY = company;
    },
    CLEAR_SELECTED_COMPANY(state) {
      state.SELECTED_COMPANY = {};
    },
    SET_KYC_KYB_DETAILS_LOADING(state, payload) {
      state.kycKybDetailsLoading = payload;
    },
    SET_KYC_KYB_DETAILS(state, payload) {
      if (payload) {
        state.kycKybDetails = payload;
        state.kycKybDetails = {
          ...state.kycKybDetails,
          kycFields: [],
          commerceNumber: !!payload?.commerceNumber
            ? payload.commerceNumber.map(val => ({ num: val }))
            : "-"
        };
        if (payload?.name && payload.name.length > 0) {
          const combinedNamesSurnamesEmails = payload.name.map(
            (firstName, index) => ({
              name: firstName,
              surname: !!payload?.surname ? payload.surname[index] : "",
              email: !!payload?.email ? payload?.email[index] : ""
            })
          );
          state.kycKybDetails = {
            ...state.kycKybDetails,
            kycFields: combinedNamesSurnamesEmails
          };
        }
        let defaultKycStatus = "rejected";
        const waitingStatuses = ["waiting", "uploaded"];
        // const deniedStatuses = ["denied"];
        if (
          "uboState" in payload &&
          "activitiesState" in payload &&
          "chartState" in payload &&
          "sourceOfFundState" in payload &&
          "idCardState" in payload
        ) {
          const {
            uboState,
            activitiesState,
            chartState,
            sourceOfFundState,
            idCardState
          } = payload;
          const statesObject = {
            uboState,
            activitiesState,
            chartState,
            sourceOfFundState,
            idCardState
          };
          const statesMappedArray = Object.values(statesObject).map(
            val => val.name
          );
          if (areAllValuesEqualTo(statesMappedArray, waitingStatuses)) {
            defaultKycStatus = "pending";
          }
          /* if (areAllValuesEqualTo(statesMappedArray, deniedStatuses)) {
            defaultKycStatus = "rejected";
          } */
        }
        state.kycFilesStatus = defaultKycStatus;
      }
    }
  },
  actions: {
    async SET_MAIN_COMPANY({ commit }, company) {
      if (!this.getters["company/MAIN_COMPANY_ID"]) {
        commit("SET_MAIN_COMPANY", company);
      }
    },
    async GET_COMPANY(state, companyId) {
      return await Api.get("companies/" + companyId);
    },
    async GET_SUB_COMPANIES(state, companyId) {
      const subCompanies = await Api.get(
        "companies/" + companyId + "/companies"
      );

      return subCompanies.data;
    },
    async GET_USERS({ state }, companyId = undefined) {
      const id = companyId ? companyId : state.MAIN_COMPANY.id;
      const users = await Api.get("companies/" + id + "/users");
      const deletedUsersCount = await Api.get(
        "companies/" + id + "/deleted-users-count"
      );
      return {
        users: users.data,
        deletedUsersCount: deletedUsersCount.data
      };
    },
    /**
     * // sample response
     * resData: {data: [{branch: 136, merchant: 135, title: "Test Merchant A.Ş."}, ..], message: '', success: true}
     */
    async GET_ALL_BRANCH_MERCHANT() {
      return await Api.get("get-all-branch");
    },
    async GET_MERCHANT_BRANCH_REPORT(_, { requestOptions }) {
      console.log(requestOptions, "requestOptions");
      const { from, to, branch } = requestOptions;
      const data = await Api.get(
        `company-orders/get-branch-report?from=${from}&to=${to}&branch=${branch}`
      );

      console.log("data", data.data);
      return {
        data: data.data
      };
    },
    async GET_DELETED_COMPANIES({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedCompanies = await Api.get(
        "companies/" + id + "/deleted-companies"
      );
      return deletedCompanies.data;
    },
    async GET_DELETED_USERS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedUsers = await Api.get("companies/" + id + "/deleted-users");
      return deletedUsers.data;
    },
    async DELETE_COMPANY(state, deleteId) {
      return await Api.delete("companies/" + deleteId);
    },
    async UNDELETE_COMPANY({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put(
        "companies/" + id + "/undelete-company/" + undeleteId,
        { headers: { "Content-Type": "application/json", Accept: "*/*" } }
      );
    },
    async UNDELETE_USER({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put("companies/" + id + "/undelete-user/" + undeleteId, {
        headers: { "Content-Type": "application/json", Accept: "*/*" }
      });
    },
    async GET_COMPANY_WALLETS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const wallets = await Api.get("companies/" + id + "/company-wallets");
      return wallets.data;
    },
    SET_SELECTED_COMPANY({ commit }, company) {
      if (company._id) {
        company.id = company._id;
      }
      commit("SET_SELECTED_COMPANY", company);
    },
    CLEAR_SELECTED_COMPANY({ commit }) {
      commit("CLEAR_SELECTED_COMPANY");
    },
    async TOGGLE_COMPANY_ACTIVE_STATUS(state, { companyId, isActive = true }) {
      // return await Api.put("companies/" + companyId, { isActive }); // old method
      return await Api.post("user-is-active-or-passive", {
        companyId,
        isActive
      });
    },
    /**
     * // sample response
     * resData: {data: [{merchant: 136, dist: 135, title: "Test Merchant A.Ş."}, ..], message: '', success: true}
     */
    async GET_ALL_MERCHANT() {
      return await Api.get("get-all-merchant");
    },
    /**
     * // sample response
     * resData: [{"id": 138,"title": "other branch"}]
     */
    async GET_ALL_BRANCH_WITH_MERCHANT(_, merchant) {
      return await Api.post("get-all-branch-with-merchant", {
        merchant
      });
    },
    async GET_PAYMENT_INFO() {
      return await Api.get("get-company-payment");
    },
    async UPDATE_PAYMENT_INFO(_, body) {
      return await Api.post("update-company-payment", body);
    },
    async GET_PAYMENT_INFO_BY_MERCHANT(_, merchant) {
      return await Api.post("get-company-payment", { merchant });
    },
    async GET_KYC_KYB({ commit, state }, companyId) {
      commit("SET_KYC_KYB_DETAILS_LOADING", true);
      state.kycKybDetails = null;
      try {
        let formData = new FormData();
        formData.append("company", companyId);
        const res = await Api.post("get-company-document-file", formData);
        commit("SET_KYC_KYB_DETAILS_LOADING", false);
        commit("SET_KYC_KYB_DETAILS", res.data?.data || undefined);
        return res;
      } catch (error) {
        commit("SET_KYC_KYB_DETAILS_LOADING", false);
        throw error;
      }
    },
    async SUBMIT_KYC_KYB(_, body) {
      return await Api.post("company-document-upload", body, {
        headers: { "content-type": "multipart/form-data" }
      });
    }
  },
  getters: {
    MAIN_COMPANY(state) {
      return state.MAIN_COMPANY;
    },
    MAIN_COMPANY_ID(state) {
      return state.MAIN_COMPANY ? state.MAIN_COMPANY.id : null;
    },
    SELECTED_COMPANY(state) {
      return state.SELECTED_COMPANY;
    },
    SELECTED_COMPANY_ID(state) {
      return state.SELECTED_COMPANY ? state.SELECTED_COMPANY.id : null;
    }
  },
  namespaced: true
};

export default company;
