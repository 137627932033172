/* eslint-disable no-extra-boolean-cast */
import Api from "../../services/api";
import JwtService from "../../services/jwt";

export const VERIFY_AUTH = "verifyAuth";
export const OFINANS_LOGIN = "ofinansLogin";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_PASSWORD = "updateUser";

export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

export const OFINANS_OTP = "ofinansOtpSend";
export const OFINANS_OTP_AGAIN = "ofinansOtpAgain";
export const OTP = "otpSend";
export const OTP_AGAIN = "otpAgain";
export const APP_LOGIN = "appLogin";

const deStructureParentData = parent => {
  const {
    id,
    title,
    email,
    taxCity,
    taxAdministration,
    taxNumber,
    hasQuickSale,
    role,
    isActive,
    phone,
    nexusMerchantInformation,
    companyRank,
    ordersTotals,
    responsibleAdminId,
    commission
  } = parent;
  return {
    id,
    title,
    email,
    taxCity,
    taxAdministration,
    taxNumber,
    hasQuickSale,
    role,
    isActive,
    phone,
    nexusMerchantInformation,
    companyRank,
    ordersTotals,
    responsibleAdminId,
    commission
  };
};

const deStructureParentRepresentativeData = representative => {
  if (representative) {
    const { id, name, email, phone, isActive } = representative;
    return {
      id,
      name,
      email,
      phone,
      isActive
    };
  }
  return null;
};

const auth = {
  state: () => ({
    errors: null,
    user: {},
    userRole: JSON.parse(sessionStorage.getItem("_userRole") || "{}"),
    isAuthenticated: !!JwtService.getAuthToken(),
    refreshTimer: null,
    token: !!JwtService.getAuthToken() ? JwtService.getAuthToken() : null
  }),
  mutations: {
    [SET_ERROR](state, error) {
      state.errors = error;
    },
    [SET_AUTH](state, user) {
      state.user = user;
      state.userRole = user.userRole;
      state.errors = {};
    },
    [SET_PASSWORD](state, password) {
      state.user.password = password;
    },
    [PURGE_AUTH](state) {
      state.isAuthenticated = false;
      state.user = {};
      state.userRole = {};
      state.errors = {};
      state.token = null;
      delete Api.defaults.headers.common["Authorization"];
      JwtService.clearAuth();
    },
    SET_APP_TOKEN(state, accessToken) {
      state.isAuthenticated = true;
      state.token = accessToken;
      JwtService.saveAuthToken(accessToken);
      Api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  },
  actions: {
    [APP_LOGIN](context, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/login", credentials)
          .then(response => {
            const hasGoogle2FA = response.status === 207;
            resolve(hasGoogle2FA);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    [OTP]({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/authenticate-otp", credentials)
          .then(({ data }) => {
            commit("SET_APP_TOKEN", data.token);
            dispatch(VERIFY_AUTH);
            resolve();
          })
          .catch(({ error }) => {
            reject(error);
          });
      });
    },
    [OTP_AGAIN](context, credentials) {
      return new Promise((resolve, reject) => {
        Api.post("auth/resend-otp", credentials)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    [VERIFY_AUTH](context) {
      return new Promise((resolve, reject) => {
        Api.get("/me")
          .then(async ({ data }) => {
            // if user is in ROLE_BRANCH set parent's commission and representative
            const parent = deStructureParentData(data.parent);
            if (data?.userRole?.roleRank === "ROLE_BRANCH" && data.merchantId) {
              const getCompany = await context.dispatch(
                "company/GET_COMPANY",
                data.merchantId
              );
              const { representative } = getCompany.data;
              parent.representative = deStructureParentRepresentativeData(
                representative
              );
              parent.representativeName =
                deStructureParentRepresentativeData(representative)?.name || "";
            }
            context.dispatch("company/SET_MAIN_COMPANY", parent).then(() => {
              context.commit(SET_AUTH, { ...data, parent: parent });
            });
            resolve(data);
          })
          .catch(err => {
            context.dispatch(LOGOUT);
            reject(err);
          });
      });
    },
    [LOGOUT](context) {
      context.commit(PURGE_AUTH);
    },
    [UPDATE_PASSWORD](context, payload) {
      const password = payload;

      return Api.put("password", password).then(({ data }) => {
        context.commit(SET_PASSWORD, data);
        return data;
      });
    }
  },
  getters: {
    activeUser(state) {
      return state.user;
    },
    activeUserId(state) {
      return state.user?.id;
    },
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    userRole(state) {
      return state.userRole;
    },
    isMerchant(state) {
      return state.userRole?.roleRank === "ROLE_MERCHANT";
    },
    isDistributor(state) {
      return state.userRole?.roleRank === "ROLE_DISTRIBUTOR";
    },
    isBranch(state) {
      return state.userRole?.roleRank === "ROLE_BRANCH";
    },
    isKYCVerified(state) {
      const checkKyc = process.env.VUE_APP_KYC_CHECK_ENABLED === "true";
      if (checkKyc) {
        return state.user?.documentState ? state.user.documentState : false;
      }
      // pass the kyc process if the env var(VUE_APP_KYC_CHECK_ENABLED) is false
      // this will help to disable kyc feature in live-app
      return true;
    },
    token(state) {
      return state.token;
    }
  }
};

export default auth;
