const ROLE_ID = {
  10: "GENESIS",
  20: "MFA",
  30: "MERCHANT",
  40: "BRANCH"
};

export const ROLE = {
  GENESIS: "GENESIS",
  MFA: "MFA",
  MERCHANT: "MERCHANT",
  BRANCH: "BRANCH"
};

export const USER_ROLE = {
  CAN_SUB_DEAL: "canSubDeal",
  HAS_MCM_ACCOUNT: "hasMCMAccount",
  HAS_WALLETS: "hasWallets",
  CAN_SELL_QR_CODE: "canSellQrCode"
};

export const getRole = roleId => {
  return ROLE[ROLE_ID[roleId]];
};
