export const dateTimeFilter = (dateTime = "", type = "date") => {
  if (dateTime && dateTime.includes("T")) {
    const splitDateTime = dateTime.split("T");
    const date = splitDateTime[0];
    const newDate = new Date(date);
    const day = `${newDate.getDate()}`.padStart(2, "0");
    const month = `${newDate.getMonth() + 1}`.padStart(2, "0");
    const year = `${newDate.getFullYear()}`;
    const formattedDate = `${day}.${month}.${year}`;
    const formattedTime = splitDateTime[1]
      ? splitDateTime[1].substring(0, 5)
      : "";
    return type === "date"
      ? formattedDate
      : `${formattedDate} ${formattedTime}`;
  }
  return "";
};
