const APP_TOKEN_KEY = "app_token";
const REFRESH_KEY = "refresh_token";
const TOKEN_ISSUED_AT = "token_issued_at";
const REFRESH_TOKEN_EXPIRY = "refresh_token_expiry";

export const saveAuthToken = token => {
  window.sessionStorage.setItem(APP_TOKEN_KEY, token);
};

export const saveRefreshToken = token => {
  window.sessionStorage.setItem(REFRESH_KEY, token);
};

export const saveRefreshTokenExpiry = expiry => {
  window.sessionStorage.setItem(REFRESH_TOKEN_EXPIRY, expiry);
};

export const saveTokenIssuedAt = issuedAt => {
  window.sessionStorage.setItem(TOKEN_ISSUED_AT, issuedAt);
};

export const getAuthToken = () => {
  return window.sessionStorage.getItem(APP_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.sessionStorage.getItem(REFRESH_KEY);
};

export const getRefreshTokenExpiry = () => {
  return window.sessionStorage.getItem(REFRESH_TOKEN_EXPIRY);
};

export const getTokenIssuedAt = () => {
  return window.sessionStorage.getItem(TOKEN_ISSUED_AT);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(APP_TOKEN_KEY);
  window.sessionStorage.removeItem(REFRESH_KEY);
};

export const clearAuth = () => {
  window.sessionStorage.removeItem(APP_TOKEN_KEY);
  window.sessionStorage.removeItem(REFRESH_KEY);
  window.sessionStorage.removeItem(TOKEN_ISSUED_AT);
  window.sessionStorage.removeItem(REFRESH_TOKEN_EXPIRY);
  window.sessionStorage.clear();
};

export default {
  saveAuthToken,
  saveRefreshToken,
  saveRefreshTokenExpiry,
  saveTokenIssuedAt,
  getAuthToken,
  getRefreshToken,
  getRefreshTokenExpiry,
  getTokenIssuedAt,
  destroyToken,
  clearAuth
};
