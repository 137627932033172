import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/plugins/vue-i18n";
import "@/plugins/portal-vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/perfect-scrollbar";
import "@/plugins/highlight-js";
import "@/plugins/inline-svg";
import "@/plugins/apexcharts";
import "@/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/plugins/formvalidation";
import "@/plugins/datatable";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { dateTimeFilter } from "./utils/date-filter";
Vue.prototype.$dateTimeFilter = dateTimeFilter;
Vue.use(VueSweetalert2);

Vue.use(require("vue-moment"));

Vue.filter("capitalize", value => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("upperCase", value => {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.filter("filterDate", value => {
  return dateTimeFilter(value);
});

Vue.filter("filterDateTime", value => {
  return dateTimeFilter(value, "date-time");
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
