import ApiMcm from "../../services/apiMcm";

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    async MPAY_CONVERT(state, params) {
      return await ApiMcm.post("miracle-pay/convert", params);
    }
  },
  namespaced: true
};
