// France
export const locale = {
  TRANSLATOR: {
    SELECT: "choisissez votre langue"
  },
  SLOGAN: "New Way of Payment Collecting",
  MENU: {
    NEW: "Nouveau",
    EDIT: "Edit",
    ACTIONS: "Actes",
    CREATE_POST: "Créer un nouveau Post",
    PAGES: "Pages",
    FEATURES: "Fonctionnalités",
    APPS: "Applications",
    GREET: "Hello"
  },
  ROUTES: {
    DASHBOARD: "Tableau de Bord",
    HOME: "Home",
    SALES_MANAGEMENT: "Sales Management",
    ALL_SALES: "All Sales",
    NEW_SALE: "New Sale",
    QR_SALE: "New Sale with QR Code",
    WALLET_MANAGEMENT: "Wallet Management",
    WALLETS: "Wallets",
    WITHDRAWALS: "Withdrawals",
    PERSONNEL_MANAGEMENT: "Personnel Management",
    ALL_PERSONNEL: "All Personnel",
    NEW_PERSONNEL: "New Personnel",
    COMPANY: "Company",
    SETTINGS: "Settings",
    ROLES: "Roles",
    LOGS: "Logs",
    LOGOUT: "Logout",
    CHANGE_PASSWORD: "Change Password",
    CORPORATE: "Corporate",
    COMPANIES: "Companies",
    BRANCHES: "Branches",
    BRANCH: "Branch"
  },
  FORM: {
    ID: "Id",
    YES: "Yes",
    NO: "No",
    OK: "OK",
    TRY_AGAIN: "Try Again",
    SAVE: "Save",
    UPDATE: "Update",
    SUBMIT: "Submit",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    EMAIL: "Email",
    FIRST_NAME: "Name",
    LAST_NAME: "Family name",
    FULL_NAME: "Name familyname",
    PASSWORD: "Mot de passe",
    PHONE: "Phone",
    LOGIN_WITH_GOOGLE: "Login with Google Two Factor Authentication",
    GOOGLE_SCAN_MESSAGE1: "Scan QR Code through",
    GOOGLE_SCAN_MESSAGE2: "",
    CLICK: "Click",
    WHEN_DONE: "when done",
    PHONE_OR_EMAIL: "Phone or Email",
    CONFIRM_PASSWORD: "Confirm Password",
    USERNAME: "Nom d'utilisateur",
    SUCCESS_TITLE: "Successful Operation",
    SUBMITTED: " successfully saved",
    UPDATED: " successfully updated",
    DELETED: " successfully deleted",
    ERROR_TITLE: "Error",
    ERROR: "An Error Occured."
  },
  AUTH: {
    GENERAL: {
      OR: "Ou",
      SUBMIT_BUTTON: "Soumettre",
      NO_ACCOUNT: "Ne pas avoir de compte?",
      SIGNUP_BUTTON: "Registre",
      FORGOT_BUTTON: "Mot de passe oublié",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Créer un compte",
      BUTTON: "Sign In",
      WELCOME: "Welcome",
      NEW_HERE: "New Here?",
      CREATE_ACCOUNT: "Create New Account",
      LOGIN: "Login",
      OTP_VERIFICATION: "OTP Verification",
      TWO_FA: "Two Factor Authentication",
      TWO_FA_GOOGLE: "Two Factor Authentication with Google",
      TWO_FA_MESSAGE:
        "Please enter the code sent to your phone or email to login",
      TWO_FA_GOOGLE_MESSAGE:
        "Please enter the code from Google Authenticator app to login",
      VERIFICATION_CODE: "Enter verification code",
      SEND_AGAIN: "Send Again",
      LOADING_MESSAGE_TITLE: "Hang On",
      LOADING_MESSAGE_SUBTITLE: "Gathering Your Info..."
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      AGREEMENT_TEXT: "I Agree the",
      AGREEMENT_LINK: "terms and conditions",
      SUCCESS: "Your account has been successfuly registered."
    },
    VALIDATION: {
      INVALID: "{{name}} n'est pas valide",
      REQUIRED: "{{name}} est requis",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  LIST: {
    CREATED_AT: "Created at",
    UPDATED_AT: "Updated at"
  },
  COMPANY: {
    COMPANY: "Company",
    BRANCH: "Branch",
    NEW_COMPANY: "Add New Company",
    NEW_BRANCH: "Add New Branch",
    SALES_REPRESENTATIVE: "Sales Representative",
    USER: "User",
    TITLE: "Title",
    TAX_ADMINISTRATION: "Tax Administration",
    TAX_CITY: "Tax Office City",
    TAX_NUMBER: "Tax Number",
    QUICK_SALE: "Quick Sale",
    MAIL_INFO_MESSAGE: "Generated user credentials will be sent to this email."
  },
  PAGES: {
    SALES_MANAGEMENT: {
      LINK_SALES: {
        NEW_SALE: "New Sale with Link",
        ORDER_INFO: "Order Info",
        ORDER_AMOUNT: "Order Amount",
        COMMISSION_RATE: "Commission Rate",
        DEDUCT_COMMISSION: "Deduct Commission from Order Amount?",
        COMMISSION_AMOUNT: "Commission Amount",
        ORDER_TOTAL: "Order Total",
        CREATE_LINK: "Create Link"
      },
      LIST: {
        ORDER_ID: "Order id",
        PRICE: "Price",
        COMMISSION: "Comission",
        TOTAL: "Total",
        STATUS: "Status",
        DATE: "Date",
        SALE: "Sale",
        PENDING: "Pending",
        COMPLETED: "Completed"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "New Sale wıth QR Code",
          CUSTOMER_INFO: "Customer Info",
          SUCCESS_TITLE: "Payment Received.",
          SUCCESS_SUBTITLE: "Thank you.",
          ERROR_SUBTITLE: "Please Try Again.",
          SCAN_QR_CODE: "Scan QR Code"
        }
      },
      PROFILE: {
        PROFILE_PAGE: "Profile Page"
      }
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Nombre d'enregistrements sélectionnés: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "éCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Les clients",
      CUSTOMERS_LIST: "Liste des clients",
      NEW_CUSTOMER: "Nouveau client",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Suppression du client",
        DESCRIPTION: "Êtes-vous sûr de supprimer définitivement ce client?",
        WAIT_DESCRIPTION: "Le client est en train de supprimer ...",
        MESSAGE: "Le client a été supprimé"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Supprimer les clients",
        DESCRIPTION:
          "Êtes-vous sûr de supprimer définitivement les clients sélectionnés?",
        WAIT_DESCRIPTION: "Les clients suppriment ...",
        MESSAGE: "Les clients sélectionnés ont été supprimés"
      },
      UPDATE_STATUS: {
        TITLE: "Le statut a été mis à jour pour les clients sélectionnés",
        MESSAGE:
          "Le statut des clients sélectionnés a été mis à jour avec succès"
      },
      EDIT: {
        UPDATE_MESSAGE: "Le client a été mis à jour",
        ADD_MESSAGE: "Le client a été créé"
      }
    }
  }
};
