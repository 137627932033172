/* eslint-disable no-console */
import Api from "../../services/api";
import i18n from "@/plugins/vue-i18n";
import { downloadUrl } from "@/utils/download-file";

const orders = {
  state: () => ({
    selectedOrder:
      JSON.parse(sessionStorage.getItem("_selectedOrder") || "{}") || null,
    viewOrder: null,
    selectedOrderMerchantId:
      JSON.parse(sessionStorage.getItem("_selectedOrder") || "{}")
        ?.merchantId || null,
    nonUserOrderWallet: JSON.parse(
      sessionStorage.getItem("_nonUsetOrderWallet") || "{}"
    )
  }),
  mutations: {
    SET_VIEW_ORDER(state, payload) {
      state.viewOrder = payload;
    },
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload;
      state.selectedOrderMerchantId = payload.merchantId;
    },
    CLEAR_SELECTED_ORDER(state) {
      state.selectedOrder = {};
      state.selectedOrderMerchantId = null;
    },
    SET_NON_USER_ORDER_WALLETS(state, payload) {
      state.nonUserOrderWallet = payload;
      // console.log('SET_NON_USER_ORDER_WALLETS.payload:', payload);
    },
    CLEAR_NON_USER_ORDER_WALLETS(state) {
      state.nonUserOrderWallet = {};
    }
  },
  actions: {
    async DELETE_ORDER(state, orderId) {
      await Api.delete("/company-orders/" + orderId);
      state.commit("SET_VIEW_ORDER", {});
    },
    UPDATE_SELECTED_QR_CURRENCY(state, payload) {
      Api.post("update-selected-currency", payload)
        .then(() => {
          console.log("UPDATE_SELECTED_QR_CURRENCY:success");
        })
        .catch(({ response }) => {
          console.error(response);
        });
    },
    CREATE_QR_SALE(state, payload) {
      return new Promise((resolve, reject) => {
        Api.post(
          "/company-orders/" + payload.orderId + "/transactions/qr-sale",
          payload,
          {
            headers: { "Content-Type": "application/json", Accept: "*/*" }
          }
        )
          .then(result => {
            resolve(result);
          })
          .catch(({ response }) => {
            reject(response);
          });
      });
    },
    async CREATE_ORDER(state, payload) {
      return new Promise((resolve, reject) => {
        Api.post("company-orders", payload)
          .then(order => {
            resolve(order.data);
          })
          .catch(({ response }) => {
            console.error(response);
            reject(response);
          });
      });
    },
    async GET_ORDER({ commit }, payload) {
      try {
        const res = await Api.get("company-orders/" + payload);
        commit("SET_VIEW_ORDER", res.data);
        commit("SET_SELECTED_ORDER", res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        commit("SET_VIEW_ORDER", null);
        return error;
      }
    },
    async GET_ORDERS_TOTAL(state, branchId) {
      const ordersTotals = await Api.get(
        `company-orders/${branchId}/orders-total`
      );
      return ordersTotals.data;
    },
    async SET_SELECTED_ORDER(state, payload) {
      await state.commit("SET_SELECTED_ORDER", payload);
    },
    async SET_VIEW_ORDER(state, payload) {
      await state.commit("SET_VIEW_ORDER", payload);
    },
    async GET_NON_USER_WALLET(state, payload) {
      try {
        const res = await Api.get(
          `/nexus/create_payment/${payload.currency}/${payload.orderId}/${payload.payment}`
        );
        state.commit("SET_NON_USER_ORDER_WALLETS", res.data);
        return res.data;
      } catch (error) {
        throw error;
      }
    },
    CLEAR_NON_USER_WALLET(state) {
      state.commit("CLEAR_NON_USER_ORDER_WALLETS");
    },
    async CHECK_ORDER_STATUS(state, orderId) {
      return await Api.get(`company-orders/${orderId}`);
    },
    async FETCH_BRANCH_SALES_REPORT(state, companyId) {
      const url = `company-orders/${companyId}/branch-sales-report`;
      const fetchedData = await Api.get(url);
      if (fetchedData.data.length > 0) {
        const exportData = fetchedData.data.map(order => ({
          ...order,
          price: `${order.price} ${order?.paymentUnitName || ""}`,
          orderTotal: `${order.orderTotal} ${order?.paymentUnitName || ""}`,
          orderStatus: order?.status
            ? i18n.t(
                `PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES.${order.status}`
              )
            : "",
          paymentStateName: order?.paymentStateId
            ? i18n.t(
                `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${order.paymentStateId}]`
              )
            : ""
        }));
        return exportData;
      }
      return [];
    },
    CLEAR_SELECTED_ORDER({ commit }) {
      commit("CLEAR_SELECTED_ORDER");
    },
    async UPDATE_COMPANY_ORDER(_, { orderId, status }) {
      return await Api.post("update-company-order-status", {
        id: orderId,
        status
      });
    },
    async DELETE_WALLET_ADDRESS(_, address) {
      return await Api.post("delete-wallet-address", { address });
    },
    async GIVE_BACK_ORDER(_, { id, walletAddress }) {
      return await Api.post("set-order-giving-back-wallet-address", {
        id,
        walletAddress
      });
    },
    async UPDATE_ORDER_FIAT({ commit, state }, { orderId, fiat }) {
      try {
        const res = await Api.post("update-order-merchant-fiat", {
          id: orderId,
          fiat
        });
        commit("SET_VIEW_ORDER", { ...state.viewOrder, fiat });
        return res;
      } catch (error) {
        commit("SET_VIEW_ORDER", { ...state.viewOrder, fiat: false });
        throw error;
      }
    },
    async GET_ORDER_RECEIPT(_, orderId) {
      return await Api.post("get-order-file", {
        order: orderId
      });
    },
    async DOWNLOAD_ORDER_RECEIPT(_, orderId) {
      try {
        const response = await Api.post("get-order-file", {
          order: orderId
        });
        const { data, file_type } = response.data;
        await downloadUrl({
          url: data,
          file_type,
          file_name: "order-receipt"
        });
        return response;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    async COMPLETE_ORDER_PAYMENT(_, orderId) {
      const res = await Api.post("update-order-underpayment-completed", {
        id: orderId
      });
      if (!res.data.success) {
        throw res.data?.message ? res.data.message : i18n.t("FORM.ERROR");
      }
      return res;
    }
  },
  getters: {
    MAIN_COMPANY_ID(state, getters, rootState, rootGetters) {
      return rootGetters["company/MAIN_COMPANY_ID"];
    },
    VIEW_ORDER(state) {
      return state.viewOrder;
    },
    SELECTED_ORDER(state) {
      return state.selectedOrder;
    },
    SELECTED_ORDER_MERCHANT_ID(state) {
      return state.selectedOrderMerchantId;
    }
  },

  namespaced: true
};

export default orders;
