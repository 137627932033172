import axios from "axios";

const baseURL = process.env.VUE_APP_API_MCM_URI;
const ApiMcm = axios.create({
  baseURL: baseURL
});

ApiMcm.interceptors.request.use(
  config => {
    const token = localStorage.getItem("mcm_token");
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(...error)
);

ApiMcm.interceptors.response.use(
  response => response,
  error => {
    if ([404, 405, 500].includes(error?.response?.status)) {
      let errorMessage =
        "An unexpected error occurred. Please try again later.";
      throw {
        ...error,
        response: {
          ...error.response,
          data: {
            ...error.response.data,
            message: errorMessage
          }
        }
      };
    }
    throw error;
  }
);
export default ApiMcm;
