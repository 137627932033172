import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import { VERIFY_AUTH } from "../store/modules/auth";
import { USER_ROLE } from "./../constants/roles";
import { RESET_LAYOUT_CONFIG } from "../store/modules/config";
import i18n from "@/plugins/vue-i18n";

Vue.use(Router);

const isAuthenticated = (to, from, next) => {
  if (store.getters["isAuthenticated"]) {
    next();
    return;
  }
  next("/login");
};

const isNotAuthenticated = (to, from, next) => {
  if (!store.getters["isAuthenticated"]) {
    next();
    return;
  }
  next("/dashboard");
};

const checkRole = role => {
  const userRole = store.getters["userRole"];
  const hasAccess = userRole[role];
  return hasAccess ? undefined : "/";
};

const checkIsDist = (to, from, next) => {
  if (store.getters["isDistributor"]) {
    next();
    return;
  }
  next("/dashboard");
};

const checkIsDistOrMerchant = (to, from, next) => {
  if (store.getters["isDistributor"] || store.getters["isMerchant"]) {
    next();
    return;
  }
  next("/dashboard");
};

const checkIsMerchantOrBranch = (to, from, next) => {
  if (store.getters["isMerchant"] || store.getters["isBranch"]) {
    next();
    return;
  }
  next("/dashboard");
};

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/MainLayout"),
      beforeEnter: isAuthenticated,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: { title: i18n.t("ROUTES.HOME") }
        },
        {
          path: "/wallet",
          name: "wallet",
          component: () => import("@/view/pages/McmWallet.vue"),
          beforeEnter: checkIsMerchantOrBranch,
          meta: { title: i18n.t("ROUTES.WALLET") }
        },
        {
          path: "/sales-management",
          component: () => import("@/view/layout/CommonLayout"),
          beforeEnter: (to, from, next) => {
            next(checkRole(USER_ROLE.CAN_SELL_QR_CODE));
          },
          children: [
            {
              path: "list",
              name: "sales-list",
              component: () => import("@/view/pages/sales-management/List.vue"),
              meta: { title: i18n.t("ROUTES.ALL_SALES") }
            },
            {
              path: "link-sales",
              name: "link-sales",
              component: () =>
                import("@/view/pages/sales-management/Link-sales.vue"),
              meta: {
                title: i18n.t(
                  "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE"
                )
              }
            },
            {
              path: "order-details/:orderID",
              name: "order-details",
              component: () =>
                import("@/view/pages/sales-management/OrderDetails.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.SALE_DETAILS") }
            },
            {
              path: "qr-sale",
              name: "qr-sale",
              component: () =>
                import("@/view/pages/sales-management/Qr-sale.vue"),
              meta: { title: i18n.t("ROUTES.QR_SALE") }
            }
          ]
        },
        {
          path: "/personel-management/list",
          name: "personel-management",
          component: () => import("@/view/pages/personel-management/List.vue")
        },
        {
          path: "/personel-management/add",
          name: "personel-add",
          component: () => import("@/view/pages/personel-management/Add.vue")
        },
        {
          path: "/merchant-branch-report-list",
          component: () => import("@/view/layout/CommonLayout"),
          beforeEnter: checkIsDistOrMerchant,
          children: [
            {
              path: "/",
              name: "merchant-branch-report-list",
              component: () =>
                import("@/view/pages/sales-management/Branch-Report-List.vue"),
              meta: { title: i18n.t("ROUTES.MERCHANT_BRANCH_REPORT") }
            },
            {
              path: "order-details/:orderID",
              name: "merchant-order-details",
              component: () =>
                import("@/view/pages/sales-management/OrderDetails.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.SALE_DETAILS") }
            }
          ]
        },
        {
          path: "refunded-list",
          name: "refunded-sales-list",
          component: () =>
            import("@/view/pages/sales-management/Branch-Report-List.vue"),
          beforeEnter: checkIsDist,
          meta: { title: i18n.t("ROUTES.REFUNDED_SALES") }
        },
        {
          path: "/wallet-management/list",
          name: "wallet-list",
          component: () => import("@/view/pages/wallet-management/List.vue"),
          beforeEnter: (to, from, next) => {
            next(checkRole(USER_ROLE.HAS_WALLETS));
          }
        },
        {
          path: "/wallet-management/withdrawal",
          name: "wallet-withdrawal",
          component: () =>
            import("@/view/pages/wallet-management/Withdrawal.vue"),
          beforeEnter: (to, from, next) => {
            next(checkRole(USER_ROLE.HAS_WALLETS));
          }
        },
        {
          path: "/corporate",
          component: () => import("@/view/layout/CommonLayout"),
          beforeEnter: (to, from, next) => {
            next(checkRole(USER_ROLE.CAN_SUB_DEAL));
          },
          children: [
            {
              path: "companies",
              name: "company-companies",
              component: () => import("@/view/pages/company/Companies.vue")
            },
            {
              path: "companies/deleted",
              name: "company-companies-deleted",
              component: () => import("@/view/pages/company/Deleted.vue")
            },
            {
              path: "companies/wallet/:companyId",
              name: "company-wallet",
              component: () => import("@/view/pages/McmWallet.vue"),
              meta: { title: i18n.t("ROUTES.WALLET") }
            },
            {
              path: "companies/company",
              name: "company-companies-company",
              component: () => import("@/view/pages/company/AddCompany.vue")
            },
            {
              path: "companies/company/:companyId",
              name: "company-companies-company-edit",
              component: () => import("@/view/pages/company/EditCompany.vue")
            },
            {
              path: "companies/company/users/:companyId",
              name: "company-companies-company-users",
              component: () => import("@/view/pages/company/Users.vue"),
              props: true,
              meta: { title: i18n.t("ROUTES.USERS") }
            },
            {
              path: "companies/company/users/deleted",
              name: "company-companies-company-users-deleted",
              component: () => import("@/view/pages/settings/Deleted.vue"),
              props: true
            },
            /* {
              path: "sales-representative-list",
              name: "sales-representative-list",
              component: () =>
                import("@/view/pages/sales-representative/List.vue"),
              beforeEnter: checkIsDist,
              meta: { title: i18n.t("ROUTES.SALES_REPRESENTATIVES") }
            },
            {
              path: "sales-representative",
              name: "sales-representative",
              component: () =>
                import("@/view/pages/sales-representative/Representative.vue"),
              beforeEnter: checkIsDist,
              props: true,
              meta: { title: i18n.t("ROUTES.SALES_REPRESENTATIVES") }
            }, */
            {
              path: "companies/company/edit-branch/:branchId",
              name: "edit-branch",
              component: () => import("@/view/pages/company/EditBranch.vue"),
              meta: {
                title: `${i18n.t("ROUTES.BRANCHES")} ${i18n.t("MENU.EDIT")}`
              }
            }
          ]
        },
        {
          path: "/pos",
          component: () => import("@/view/layout/CommonLayout"),
          children: [
            {
              path: "pos-list",
              name: "pos-list",
              component: () => import("@/view/pages/pos/PosList.vue"),
              meta: { title: i18n.t("ROUTES.POS_LIST") }
            },
            {
              path: "pos-details",
              name: "pos-details",
              component: () => import("@/view/pages/pos/PosDetails.vue"),
              meta: { title: i18n.t("PAGES.SALES_MANAGEMENT.POS.POS") }
            }
          ]
        },
        // {
        //   path: "/pos",
        //   component: () => import("@/view/layout/CommonLayout"),
        //   beforeEnter: checkIsDist,
        //   children: [
        //     {
        //       path: "pos-list",
        //       name: "pos-list",
        //       component: () => import("@/view/pages/pos/PosList.vue")
        //     }
        //   ]
        // },
        {
          path: "/settings/general",
          name: "settings-general",
          component: () => import("@/view/pages/settings/General.vue"),
          props: true,
          meta: { title: i18n.t("ROUTES.COMPANY") }
        },
        {
          path: "/settings/users",
          name: "settings-users",
          component: () => import("@/view/pages/settings/Users.vue"),
          props: true,
          meta: { title: i18n.t("ROUTES.USERS") }
        },
        {
          path: "/settings/users/user",
          name: "settings-users-user",
          component: () => import("@/view/pages/settings/User.vue"),
          props: true,
          meta: { title: i18n.t("ROUTES.USERS") }
        },
        {
          path: "/settings/profile",
          name: "settings-users-user-profile",
          component: () => import("@/view/pages/settings/User.vue"),
          props: true,
          meta: { title: i18n.t("ROUTES.PROFILE") }
        },
        /* {
          path: "/settings/users/deleted",
          name: "settings-users-deleted",
          component: () => import("@/view/pages/settings/Deleted.vue"),
          props: true
        }, */
        {
          path: "/settings/logs",
          name: "settings-logs",
          component: () => import("@/view/pages/settings/Logs.vue")
        },
        {
          path: "/settings/change-password",
          name: "change-password",
          component: () => import("@/view/pages/ChangePassword.vue"),
          meta: { title: i18n.t("ROUTES.CHANGE_PASSWORD") }
        },
        {
          path: "/kyc-kyb",
          name: "kyc-kyb",
          component: () => import("@/view/pages/KycKyb.vue"),
          meta: { title: "KYC-KYB" }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/Login"),
      beforeEnter: isNotAuthenticated,
      meta: { title: i18n.t("AUTH.LOGIN.TITLE") }
    },
    {
      name: "api-doc",
      path: "/api-doc",
      component: () => import("@/view/pages/ApiDoc"),
      meta: { title: i18n.t("virtual_pos_api_doc.title") }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Error.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  const isLoginRoute = ["login"].includes(to.name);
  if (!isLoginRoute && !store.getters["isAuthenticated"]) {
    if (["api-doc", "404"].includes(to.name)) next();
    else next({ name: "login" });
  } else if (!isLoginRoute && store.getters["isAuthenticated"]) {
    store
      .dispatch(VERIFY_AUTH)
      .then(() => {
        if (to.name !== "kyc-kyb" && !store.getters["isKYCVerified"]) {
          if (to.name === "change-password") next();
          else next({ name: "kyc-kyb" });
        }
        if (to.name === "kyc-kyb" && store.getters["isKYCVerified"]) {
          next({ name: "dashboard" });
        }
      })
      .catch(() => {
        new Vue().$bvToast.toast("Your session has been expired.", {
          title: "Information",
          variant: "danger",
          autoHideDelay: 5000
        });
      });
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

const getCompanyOrBranchRoutesTitle = routeName => {
  let branchOrCompany = store.getters["isMerchant"]
    ? i18n.t("ROUTES.BRANCHES")
    : i18n.t("ROUTES.COMPANIES");
  const companyRoutesTitle = {
    "company-companies": branchOrCompany,
    "company-companies-company": `${branchOrCompany} - ${i18n.t("MENU.NEW")}`,
    "company-companies-company-edit": `${branchOrCompany} - ${i18n.t(
      "MENU.EDIT"
    )}`
  };
  return {
    titleKeys: Object.keys(companyRoutesTitle),
    title: companyRoutesTitle[routeName]
  };
};

router.afterEach(to => {
  Vue.nextTick(() => {
    let docTitle = to.meta?.title ? to.meta?.title : "MiraclePay";

    if (getCompanyOrBranchRoutesTitle(to.name).titleKeys.includes(to.name)) {
      docTitle = getCompanyOrBranchRoutesTitle(to.name).title;
    }

    document.title = docTitle;
  });
});

export default router;
