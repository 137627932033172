import { SET_AUTH, PURGE_AUTH } from "../modules/auth";

const clearItem = key => {
  window.sessionStorage.removeItem(key);
};
const storageItem = (key, item) => {
  clearItem(key);
  window.sessionStorage.setItem(key, JSON.stringify(item));
};

const mutations = {
  [SET_AUTH]: payload => {
    storageItem("_userRole", payload.userRole);
  },
  [PURGE_AUTH]: () => {
    clearItem("_userRole");
  },
  ["company/SET_MAIN_COMPANY"]: payload => {
    storageItem("_mainCompany", payload);
  },
  ["user/SET_SELECTED_USER"]: payload => {
    storageItem("_selectedUser", payload);
  },
  ["user/CLEAR_SELECTED_USER"]: () => {
    clearItem("_selectedUser");
  },
  ["orders/SET_SELECTED_ORDER"]: payload => {
    storageItem("_selectedOrder", payload);
  },
  ["orders/CLEAR_SELECTED_ORDER"]: () => {
    clearItem("_selectedOrder");
  },
  ["orders/SET_NON_USER_ORDER_WALLETS"]: payload => {
    const { address, id, status } = payload;
    storageItem("_nonUsetOrderWallet", { address, id, status });
  },
  ["orders/CLEAR_NON_USER_ORDER_WALLETS"]: () => {
    clearItem("_nonUsetOrderWallet");
  },
  ["pos/SET_SELECTED_POS"]: payload => {
    payload.id = payload._id ?? payload.id;
    storageItem("_selectedPos", payload);
  },
  ["pos/CLEAR_SELECTED_POS"]: () => {
    clearItem("_selectedPos");
  },
  ["representative/SET_SELECTED_REPRESENTATIVE"]: payload => {
    payload.id = payload._id ?? payload.id;
    storageItem("_selectedRepresentative", payload);
  },
  ["representative/CLEAR_SELECTED_REPRESENTATIVE"]: () => {
    clearItem("_selectedRepresentative");
  }
};

const statePersistorPlugin = store => {
  store.subscribe(mutation => {
    /* if (type === "SOME_MUTATION_TYPE") {
        // do some things with payload
    }
    ...
    */

    const { type, payload } = mutation;
    if (type in mutations) {
      mutations[type](payload);
    }
  });
};

export default statePersistorPlugin;
