import Api from "@/services/api";
import i18n from "@/plugins/vue-i18n";

const disabledCurrencyCodes = ["USDTTRC", "USDTPLYGN", "USDTBNB"];

const mcmWallet = {
  state: () => ({
    isLoading: false,
    isError: {
      status: false,
      message: i18n.t("FORM.ERROR")
    },
    balances: [],
    chartOptions: {
      chart: {
        redrawOnParentResize: true,
        type: "donut"
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270
        }
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient"
      },
      legend: {
        position: "bottom"
      },
      labels: []
    },
    chartSeries: []
  }),
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BALANCES(state, payload) {
      state.balances = [];
      state.chartSeries = [];
      state.chartOptions.labels = [];
      for (let balance of payload) {
        if (disabledCurrencyCodes.includes(balance.currency_code)) {
          continue;
        }
        state.balances.push(balance);
        const currencyTotal = parseFloat(balance.default_currency_total);
        if (currencyTotal) {
          state.chartSeries.push(currencyTotal);
          state.chartOptions.labels.push(balance.currency_code);
        }
      }
    },
    SET_ERROR(state, payload) {
      state.isError = {
        ...state.isError,
        status: payload.status,
        message: payload?.message || i18n.t("FORM.ERROR")
      };
    }
  },
  actions: {
    async FETCH_BALANCES({ commit }, companyId) {
      commit("SET_LOADING", true);
      commit("SET_ERROR", { status: false });
      try {
        const res = companyId
          ? await Api.post("get-user-wallet-and-balances", {
              company: companyId
            })
          : await Api.get("get-user-wallet-and-balances");
        commit("SET_LOADING", false);
        let data = [];
        if (res.data?.success && res.data?.data?.data?.length > 0) {
          data = res.data.data.data;
        }
        commit("SET_BALANCES", data);
        if (!res.data?.success) {
          commit("SET_ERROR", { status: true });
        }
        return res;
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_ERROR", { status: true });
        console.log("FETCH_BALANCES@error ", error);
      }
    }
  },
  namespaced: true
};

export default mcmWallet;
