import Vue from "vue";
import Api from "../../services/api";

const wallets = {
  state: () => ({
    wallets: []
  }),
  mutations: {
    SET_WALLETS(state, payload) {
      state.wallets = payload;
    }
  },
  actions: {
    GET_WALLETS({ commit }) {
      Api.get("company_wallets")
        .then(res => {
          commit("SET_WALLETS", res.data["hydra:member"]);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    async GET_COMPANY_WALLETS({ getters }, merchant = false) {
      const id = merchant
        ? getters.SELECTED_ORDER_MERCHANT_ID
        : getters.MAIN_COMPANY_ID;
      const wallets = await Api.get("companies/" + id + "/company-wallets");
      return wallets.data;
    }
    // CREATE_WALLET({ state, commit }, params) {
    //   Api.post('company_wallets', params)
    //     .then(({ data }) => {
    //     })
    //     .catch(({ response }) => {
    //       console.error(response);
    //     });
    // },
  },
  getters: {
    MAIN_COMPANY_ID(state, getters, rootState, rootGetters) {
      return rootGetters["company/MAIN_COMPANY_ID"];
    },
    SELECTED_ORDER_MERCHANT_ID(state, getters, rootState, rootGetters) {
      return rootGetters["orders/SELECTED_ORDER_MERCHANT_ID"];
    }
  },

  namespaced: true
};

export default wallets;
