const getFileExtension = type => {
  switch (type) {
    case "application/pdf":
      return "pdf";
    case "image/png":
      return "png";
    case "image/jpg":
    case "image/jpeg":
      return "jpg";
    default:
      return "pdf";
  }
};
export const downloadFileBlob = async ({ data, file_type, file_name }) => {
  try {
    const blob = new Blob([data], { type: file_type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${file_name}.${getFileExtension(file_type)}`;
    link.click();
    URL.revokeObjectURL(link.href);
    return "success";
  } catch (err) {
    console.log("downloadFileBlob: ", err);
    throw err;
  }
};

export const downloadBase64File = async ({ data, file_type, file_name }) => {
  try {
    const link = document.createElement("a");
    link.href = `data:${file_type};base64,${data}`;
    link.download = `${file_name}.${getFileExtension(file_type)}`;
    link.click();
    URL.revokeObjectURL(link.href);
    return "success";
  } catch (err) {
    console.log("downloadBase64File: ", err);
    throw err;
  }
};

export const downloadUrl = async ({ url, file_name, file_type }) => {
  try {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.download = `${file_name}.${getFileExtension(file_type)}`;
    link.click();
    URL.revokeObjectURL(link.href);
    return "success";
  } catch (err) {
    console.log("downloadUrl: ", err);
    throw err;
  }
};
