import Api from "../../services/api";

const user = {
  state: () => ({
    SELECTED_USER: JSON.parse(sessionStorage.getItem("_selectedUser") || "{}")
  }),
  mutations: {
    SET_SELECTED_USER(state, user) {
      state.SELECTED_USER = user;
    },
    CLEAR_SELECTED_USER(state) {
      state.SELECTED_USER = {};
    }
  },
  actions: {
    async GET_USER(state, userId) {
      return await Api.get("users/" + userId);
    },
    SET_SELECTED_USER({ commit }, user) {
      commit("SET_SELECTED_USER", user);
    },
    CLEAR_SELECTED_USER({ commit }) {
      commit("CLEAR_SELECTED_USER");
    },
    SET_CURRENT_USER_SELECTED_USER({ commit, rootState }) {
      const activeUser = rootState.auth.user;
      commit("SET_SELECTED_USER", activeUser);
    },
    async DELETE_USER(state, deleteId) {
      return await Api.delete("users/" + deleteId);
    },
    async GET_GOOGLE_QR_CODE({ rootState }) {
      const activeUserId = rootState.auth.user.id;
      return await Api.get(
        "users/" + activeUserId + "/generate-google-qr-code"
      );
    },
    async CONFIRM_GOOGLE_QR_CODE({ rootState }) {
      const activeUserId = rootState.auth.user.id;
      return await Api.post(
        "users/" + activeUserId + "/confirm-google-qr-code",
        { status: true }
      );
    },
    async TOGGLE_USER_ACTIVE_STATUS(state, { userId, isActive = true }) {
      return await Api.put("users/" + userId, { isActive });
    }
  },
  getters: {
    SELECTED_USER(state) {
      return state.SELECTED_USER;
    },
    SELECTED_USER_ID(state) {
      return state.SELECTED_USER ? state.SELECTED_USER.id : null;
    }
  },
  namespaced: true
};

export default user;
