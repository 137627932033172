/**
 * Check if all arr values equals and includes in arrIncludes
 * @param arr
 * @param arrIncludes
 * @returns {boolean}
 */
export function areAllValuesEqualTo(arr = [], arrIncludes = []) {
  if (arr.length === 0 || arrIncludes.length === 0) return true;
  for (const element of arr) {
    if (!arrIncludes.includes(element)) {
      return false;
    }
  }
  return true;
}
